
<div
  ngbDropdown
  container="body"
  class="d-flex align-items-center ms-1 rounded justify-content-end w-100"
  id="kt_header_user_menu_toggle_profile"
  (click)="handleClickProfile()"
>

<!-- [ngClass]="isPublicHeader ? 'bg-hover-opacity-50' : 'bg-hover-opacity-50'" -->
<!-- ngbDropdownToggle -->
  <div

    class="btn btn-flex align-items-center py-2 px-2 px-md-3 w-100 justify-content-around user-profile-menu
    bg-hover-user-profile"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    id="kt_header_user_menu_toggle_profile_toggle"
  >
    <div class="d-flex align-items-center justify-content-between w-100">
      <div
        class="d-flex flex-column align-items-end justify-content-center me-2 me-md-4 profile-name"
      >
        <span class=" opacity-75 fs-8 fw-bold lh-1 mb-1 text-start">{{
          profileData?.name || "Un Named" | titlecase
        }}</span>
        <span class=" fs-8 fw-bolder lh-1" *ngIf="profileData?.accountType">{{
          redirectToProfile ? 'My Profile' : ((profileData?.accountType|accountTypeToLabel) || "No Role" | titlecase)
        }}</span>
      </div>

      <span class="badge badge-secondary ms-2 " placement="left" ngbTooltip="Total Profile Views: {{notificationsCount?.totalProfileViews|number}}">
        <i class="bi bi-eye-fill cursor-pointer"></i>
        {{notificationsCount?.totalProfileViews ? (notificationsCount?.totalProfileViews | numberFormatter) : 0 }}
      </span>

    </div>


    <div class="symbol symbol-30px symbol-md-40px position-relative">
      <img
        alt="userPhoto"
        class="bg-white shadow-sm"
        [src]="'/images/no-logo-uploaded.png' | assetsImagekitUrlAppender"
        *ngIf="!s3LogoUrl"
        />
        <!-- src="assets/images/upload-image-placeholder.png" -->
      <img
        class="bg-white shadow-sm"
        alt="userPhoto"
        [src]="(s3LogoUrl | imagekitUrlAppender)+ '?tr=w-70,h-70,cm-pad_resize'"
        *ngIf="s3LogoUrl"
      />

      <div
        class="bg-green ms-2 rounded cursor-pointer position-absolute  "
        *ngIf="socketService.isUserOnline(profileData?.uuid)"
        ngbTooltip="Online"
          style="height: 10px; width: 10px;right: -5px;top: -5px;">

      </div>
    </div>

  </div>


  <div
    ngbDropdownMenu
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <ng-container *ngIf="profileData">
      <div class="menu-item px-5" *ngIf="isPublicHeader">
        <a
          class="menu-link px-5"
          ngbDropdownItem
          [routerLink]="ACCOUNT_TYPE_TO_DASHBOARD_UTL[profileData.accountType]"
          >Dashboard</a
        >
      </div>

      <div class="menu-item px-5"
      *ngIf="profileData.accountType !== ACCOUNT_TYPE.OTHER && profileData.accountType !== ACCOUNT_TYPE.JOB_SEEKER">
        <a class="menu-link px-5" ngbDropdownItem [routerLink]="profilePageUrl"
          >My Profile</a
        >
      </div>

      <ng-container *ngIf="brandDetails?.features?.multiple_profiles">
        <div class="separator my-2"></div>
        <div class="menu-item px-5 my-3" ngbDropdownToggle>
          <div class="text-center btn-outline border-secondary w-100 btn text-hover-primary px-5" (click)="openChangeProfileModal()">
            <i class="bi bi-arrow-repeat fs-3 text-dark"></i>
            Switch Profile
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="profileData.canAddMembers && profileData.accountType !== ACCOUNT_TYPE.OTHER && profileData.accountType !== ACCOUNT_TYPE.JOB_SEEKER && !profileData.parentId">
        <div class="separator my-2"></div>
        <div class="menu-item px-5" >
          <a class="menu-link px-5" ngbDropdownItem [routerLink]="'/team/list'"
            >Add/Delete User</a
          >
        </div>
      </ng-container>


      <!-- <div
        class="menu-item px-5"
        *ngIf="profileData.accountType === ACCOUNT_TYPE.STARTUP"
      >
        <a
          class="menu-link px-5"
          ngbDropdownItem
          [routerLink]="'/startups/edit/pitch-deck-documents'"
          >Supporting documents</a
        >
      </div> -->

      <div class="separator my-2" *ngIf="profileData.accountType !== ACCOUNT_TYPE.OTHER && profileData.accountType !== ACCOUNT_TYPE.JOB_SEEKER"></div>

      <!-- <div class="menu-item px-5">
        <a class="menu-link px-5" ngbDropdownItem [routerLink]="'/connections'"
          >Connections</a
        >
      </div> -->

      <div class="menu-item px-5">
        <a
          class="menu-link px-5"
          ngbDropdownItem
          [routerLink]="'/account/edit/profile'"
          >Account Settings</a
        >
      </div>

      <div class="menu-item px-5">
        <a
          href="javascript:void(0);"
          ngbDropdownItem
          class="menu-link px-5"
          (click)="handleLogout()"
        >
          Sign Out
        </a>
      </div>
    </ng-container>
  </div>

</div>

