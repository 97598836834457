// export const COMETCHAT_CONSTANTS = {
//   APP_ID: "2147702d450ce070",
//   REGION: "us",
//   AUTH_KEY: "76815f91a8ec6794c3d483b36c692b50e5a9c5db",
// };

import { ACCOUNT_TYPE } from "./app/core/domain";

export const COMETCHAT_CONSTANTS = {
  APP_ID: "21492160ff152033",
  REGION: "eu",
  AUTH_KEY: "cedeb951d501d68f1f83d14fd6d180a89584162b",
};
export const HTTPS_REQUIRED_URL = '(http(?:s)?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
export const EMAIL_REQUIRED_URL = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'

export const accountTypelabelMap = {
  [ACCOUNT_TYPE.SERVICE_PROVIDER]: 'Service Provider',
  [ACCOUNT_TYPE.PROGRAM_OFFICE]: 'Program Office',
}

export const STICKY_HEADER_THRESOLD = 30
