import slugify from "slugify";
import * as moment from 'moment-timezone';
import { IBrandDetails } from "src/app/core/domain/brand.model";
import pluralize from 'pluralize'
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { IMeetingAvailability, METTING_AVAILABILITY } from "src/app/core/domain/meetings.model";
import { ACCOUNT_TYPE, AccountTypeTitleKey } from "src/app/core/domain";
import { accountTypelabelMap } from "src/CONSTS";
import { IProfileDetails } from "src/app/core/domain/profile.model";
import { getTitleFromNavLink, MissingFormKeyToUrlMapping } from "../constants/constants";

export const createSlug = (name) => {
  return slugify(name, {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: true,     // strip special characters except replacement, defaults to `false`
    locale: 'vi',       // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
  });

  // const slug = name.split(' ').join('-').toLocaleLowerCase();
  // return slug;
}

export function generateTimeSlots(interval: number = 15): string[] {
  let times = [];
  let start = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  for (let i = 0; i < (24 * 60) / interval; i++) {
    times.push(start.format('HH:mm'));
    start.add(interval, 'minutes');
  }

  return times;
}

export function generateTimeSlotsWithAMPM(interval: number = 15): Array<{ label: string, value: string }> {
  let times = generateTimeSlots(interval);

  const mappedData = times.map((e) => {
    return { value: e, label: moment(e, 'HH:mm').format('hh:mm A') }
  })
  return mappedData;
}

/**
 * Input 23:23 or 02:10 in local time, returns uat time
 */
export function convertToUTC(time: string, format: string = 'HH:mm'): string {
  // create a moment object using the given local time
  let localTime = moment(time, format);

  // convert the local time moment to a UTC moment
  let utcTime = localTime.tz('UTC');

  // format the UTC time as a string and return
  return utcTime.format(format);
}

export function convertToLocalTime(time: string, format: string = 'HH:mm'): string {
  // create a moment object using the given UTC time and specifying that the input is in UTC
  let utcTime = moment.utc(time, format);

  // convert UTC time to local time
  let localTime = utcTime.local();

  // format the local time as a string and return
  return localTime.format(format);
}

/**
 * @param times Creates time slots array alsp converts from utc to local time
 */
export function createTimeSlots(times: any = [], localTime = false, singleSlotTime=15): string[] {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeSlots: string[] = [];

  times.forEach((time: any) => {
    const format = 'HH:mm';
    let startTime = localTime ?moment(time.startTime, format):moment.utc(time.endTime, format);
    let endTime = localTime ?moment(time.endTime, format):moment.utc(time.endTime, format);
    if (time.endTime == '00:00') {
      endTime.add(1, 'day')
    }
    let currentTime = startTime;

    while (currentTime <= endTime) {
      const localTime = currentTime.tz(timeZone).format(format);
      timeSlots.push(localTime);
      currentTime = currentTime.add(singleSlotTime, 'minutes');
    }
  });

  return timeSlots;
}

export const isDisabledDateForMeeting = (date: NgbDate, calenderAvilablity: IMeetingAvailability, calendar: NgbCalendar, disabledDaysIndex: any[]) => {
  if (calenderAvilablity.dates?.length) {
    const monthPadded = date.month.toString().padStart(2, '0')
    const datePadded = date.day.toString().padStart(2, '0')
    const foundDate = calenderAvilablity.dates.find((e) => e.date === `${date.year}-${monthPadded}-${datePadded}`)
    if (foundDate) {
      if (foundDate.closed) {
        return true
      } else {
        return false
      }
    }
  }

  return (disabledDaysIndex.includes(calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
    ? true
    : false;
}

export const findNextAvailableDate = (calenderAvilablity: IMeetingAvailability, calendar: NgbCalendar, disabledDaysIndex: any[], startDate=  new Date()) => {
  const today = startDate;
  const totalDays = moment().daysInMonth();
  const month = new Date(today).getMonth() + 1;
  const day = new Date(today).getDate()
  const datesToCheck = []
  for (let i = day; i <= totalDays; i++) {
    datesToCheck.push({
      month: month,
      day: i,
      year: today.getFullYear()
    })
  }

  const availableDateFound = datesToCheck.find((date) => {
    const isDisabled = isDisabledDateForMeeting(date, calenderAvilablity, calendar, disabledDaysIndex)
    return !isDisabled
  })
  console.log('datesToCheck', datesToCheck, availableDateFound)
  return availableDateFound
}


export const createLookingForOptionsFromBrandDetails = (brandDetails: IBrandDetails) => {
  const defaultSelect = brandDetails.features.looking_for_section ? false : true;
  const lookingForOptions = [{
    label: 'Fund Raising',
    value: 'fundraising',
    selected: defaultSelect,
    show: true
  },
  {
    label: 'Hiring',
    value: 'tech_hiring',
    selected: defaultSelect,
    show: brandDetails.features.jobs
  },
  {
    label: 'Market Access',
    value: 'customer_access',
    selected: defaultSelect,
    show: brandDetails.users.corporates
  },
  {
    label: 'Mentorship',
    value: 'mentorship',
    selected: defaultSelect,
    show: brandDetails.users.mentors
  },
  {
    label: 'Business Services',
    value: 'business_services',
    selected: defaultSelect,
    show: brandDetails.users.service_providers
  }];

  return lookingForOptions
}


export const isDateInCurrentWeek = (date) => {
  // Get the current date
  const currentDate = moment();

  // Get the start and end of the current week
  const startOfWeek = currentDate.clone().startOf('week');
  const endOfWeek = currentDate.clone().endOf('week');

  // Check if the provided date is within the current week
  return moment(date).isBetween(startOfWeek, endOfWeek, null, '[]');
};



export const getFavIconUrl = (domain) => {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=128`
}

export const getYouTubeThumbnail = (url) => {
  let videoId = getIdFromUrl(url);
  return 'https://img.youtube.com/vi/' + videoId + '/maxresdefault.jpg';
}

export const getIdFromUrl = (url) => {
  let videoId;
  if (url.includes('youtu.be')) {
    videoId = url.split('/').pop();
  } else if (url.includes('youtube.com/embed')) {
    videoId = url.split('/').pop();
  } else { // Assuming it's a watch URL
    var urlObj = new URL(url);
    videoId = urlObj.searchParams.get('v');
  }
  return videoId
}

export const getEmbedUrl = (id) => {
  return `https://www.youtube.com/embed/${id}`
}

export function toSingular(plural = '') {
  return pluralize.singular(plural)
}

export function formatEnumStringForUI(inputString) {
  // Split the input string by underscores
  let words = inputString.split('_');

  // Capitalize the first letter of each word
  let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  let formattedString = formattedWords.join(' ');

  return formattedString;
}





export const calculateSlots = async ({
  date,
  calendar,
  calenderAvilablity,
  brandDetails,
  timeSlots,
  dateAvailability,
  localtime = false,
  bufferTime = 0,
  calculatingEndTime = false
}) => {
  const dayIndex = calendar.getWeekday(new NgbDate(date.year, date.month, date.day))
  console.log('calenderAvilablity', calenderAvilablity)
  const dayInfo = calenderAvilablity.days.find((e) => e.dayIndex === dayIndex)
  console.log('update date', date, dayIndex, calenderAvilablity.days, { dayInfo })

  const month = ('' + date.month).length === 2 ? date.month : '0' + date.month
  const day = ('' + date.day).length === 2 ? date.day : '0' + date.day

  const dateAvailabilityFromUser = calenderAvilablity.dates || [];
  const selectedDateFormatted = `${date.year}-${month}-${day}`
  const foundDateAvailabilityFromUser = dateAvailabilityFromUser.find((e) => e.date === selectedDateFormatted)
  if(foundDateAvailabilityFromUser?.times?.length) {
    foundDateAvailabilityFromUser.times = foundDateAvailabilityFromUser.times.filter((e)=> e.startTime !== null && e.endTime !== null)
  }
  let times = (calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days || calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.specific_days) ? dayInfo.times : []
  console.log('foundDateAvailabilityFromUser', foundDateAvailabilityFromUser, times, !foundDateAvailabilityFromUser?.times?.length)
  console.log('calenderAvilablity', calenderAvilablity)

  if ((!times?.[0]?.startTime || !times?.[0]?.endTime) && !foundDateAvailabilityFromUser?.times?.length) {
    console.log('')
    timeSlots = generateTimeSlotsWithAMPM(calenderAvilablity.hourlySlotTime || brandDetails?.features?.meeting_time_slot_difference_in_mins);
  } else {
    const slots = createTimeSlots(foundDateAvailabilityFromUser?.times?.length ? foundDateAvailabilityFromUser?.times : times, localtime, calenderAvilablity.hourlySlotTime);
    console.log('slotess------', slots)
    timeSlots = slots.map((e) => {
      return { value: e, label: moment(e, 'HH:mm').format('hh:mm A') }
    })
  }

  // if (foundDateAvailabilityFromUser?.times?.length) {
  //   foundDateAvailabilityFromUser.times.forEach((d) => {
  //     const tF = d.startTime;
  //     const tT = d.endTime;
  //     const startIndex = timeSlots.findIndex((e) => e.value === tF);
  //     const endIndex = timeSlots.findIndex((e) => e.value === tT);
  //     if (startIndex >= 0 && endIndex >= 0) {
  //       const sliceI = (endIndex - startIndex) + 1
  //       timeSlots.splice(startIndex, sliceI)
  //     }
  //   })
  // }

  // const dateAvailability = await this.meetingService.getUsersAvailabilityByDate(uid, selectedDateFormatted).toPromise()
  console.log('dateAvailability',dateAvailability, selectedDateFormatted)
  console.log('timeee ', timeSlots)
  dateAvailability.forEach((d) => {
    let tF, tT;
    if(localtime) {
      tF = ((d.timeFrom||d.startTime).split(':').slice(0, 2).join(":"))
      tT = ((d.timeTo||d.endTime).split(':').slice(0, 2).join(":"))
    }else{
      tF = convertToLocalTime((d.timeFrom||d.startTime).split(':').slice(0, 2).join(":"))
      tT = convertToLocalTime((d.timeTo||d.endTime).split(':').slice(0, 2).join(":"))
    }

    const startIndex = timeSlots.findIndex((e) => e.value === tF);
    const endIndex = timeSlots.findIndex((e) => e.value === tT);

    if (startIndex >= 0 && endIndex >= 0) {
      // const sliceI = (endIndex - startIndex) + 1
      const sliceI = (endIndex - startIndex)
      if(calculatingEndTime){
        timeSlots.splice(startIndex+1, sliceI)
      }else{
        console.log('start indexxx', startIndex,sliceI,timeSlots)
        timeSlots.splice(startIndex, sliceI)
      }
      // timeSlots.splice(startIndex, sliceI)
      // timeSlots.splice(startIndex+1, sliceI)
    }


    // Edge case handling: starttime of any slots shouldn't be present in start time
    if(!calculatingEndTime) {
      const newStartIndex = timeSlots.findIndex((e) => e.value === tF);
      if(newStartIndex>=0) {
        timeSlots.splice(newStartIndex,1)
      }
    }


    console.log({ tF, tT, startIndex, endIndex })
  })
  console.log('timeee 2', timeSlots)
  timeSlots = filterPastTimes(timeSlots, selectedDateFormatted);
  console.log('timeee 3', timeSlots)
  timeSlots = filterBufferTime(timeSlots, selectedDateFormatted, bufferTime);
  console.log('timeee 4', timeSlots)
  return timeSlots;
}


function filterBufferTime(timesArray, dateParam, bufferTime = 0) {
   // Get the current time and calculate the minimum allowed time based on bufferTime
   const now = moment();
    const givenDate = moment(dateParam); // Convert the date parameter to a moment object
   const bufferDuration = moment.duration(bufferTime, 'hours');
   const minAllowedTime = now.add(bufferDuration).format('HH:mm');

   // Filter out time slots that are within the buffer period
  if (givenDate.isSame(now, 'day')) {
    timesArray = timesArray.filter(slot => slot.value > minAllowedTime);
  }
  return timesArray
}

function filterPastTimes(timesArray, dateParam) {
  const now = moment(); // Current time in local timezone
  const givenDate = moment(dateParam); // Convert the date parameter to a moment object

  // Check if the given date is today
  if (givenDate.isSame(now, 'day')) {
      return timesArray.filter(time => {
          const timeMoment = moment(time.value, "HH:mm");
          return timeMoment.isAfter(now); // Keep only times after the current time
      });
  }

  // If the given date is not today, return the original array
  return timesArray;
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getAccountTypeTitle = (accType:ACCOUNT_TYPE):string=> {
  const brandDetails:IBrandDetails = window.brandDetails || JSON.parse(localStorage.getItem('brandDetails') || '{}')
  return formatEnumStringForUI(brandDetails.features[AccountTypeTitleKey[accType]]|| accountTypelabelMap[accType] || accType)
}

export const getUserTypeArray= (profileData: IProfileDetails) => {
  const preparedData = {}
  profileData.connectionMatrix.forEach((matrix) => {
    preparedData[matrix.otherProfileType || matrix.profileType2] = {
      canSearch: matrix.canSearch,
      canConnect: matrix.canConnect
    }
  })

  const brandDetails:IBrandDetails = window.brandDetails
  const userTypesArr = [
    brandDetails.users.startups && preparedData[ACCOUNT_TYPE.STARTUP].canSearch ? {
      type: ACCOUNT_TYPE.STARTUP,
      label: getAccountTypeTitle(ACCOUNT_TYPE.STARTUP)
    } : null,
    brandDetails.users.investors&& preparedData[ACCOUNT_TYPE.INVESTOR].canSearch ? {
      type: ACCOUNT_TYPE.INVESTOR,
      label: getAccountTypeTitle(ACCOUNT_TYPE.INVESTOR)
    } : null,
    brandDetails.users.mentors && preparedData[ACCOUNT_TYPE.MENTOR].canSearch? {
      type: ACCOUNT_TYPE.MENTOR,
      label: getAccountTypeTitle(ACCOUNT_TYPE.MENTOR)
    } : null,
    brandDetails.users.corporates && preparedData[ACCOUNT_TYPE.CORPORATE].canSearch? {
      type: ACCOUNT_TYPE.CORPORATE,
      label: getAccountTypeTitle(ACCOUNT_TYPE.CORPORATE)
    } : null,
    brandDetails.users.service_providers && preparedData[ACCOUNT_TYPE.SERVICE_PROVIDER].canSearch? {
      type: ACCOUNT_TYPE.SERVICE_PROVIDER,
      label: getAccountTypeTitle(ACCOUNT_TYPE.SERVICE_PROVIDER)
    } : null,
    brandDetails.users.partners && preparedData[ACCOUNT_TYPE.PARTNER].canSearch? {
      type: ACCOUNT_TYPE.PARTNER,
      label: getAccountTypeTitle(ACCOUNT_TYPE.PARTNER)
    } : null,
    brandDetails.users.program_offices&& preparedData[ACCOUNT_TYPE.PROGRAM_OFFICE].canSearch ? {
      type: ACCOUNT_TYPE.PROGRAM_OFFICE,
      label: getAccountTypeTitle(ACCOUNT_TYPE.PROGRAM_OFFICE)
    } : null,
    brandDetails.users.individuals && preparedData[ACCOUNT_TYPE.INDIVIDUAL].canSearch? {
      type: ACCOUNT_TYPE.INDIVIDUAL,
      label: getAccountTypeTitle(ACCOUNT_TYPE.INDIVIDUAL)
    } : null,
  ].filter((e)=>!!e)
  return userTypesArr
}


export const sortEventsAndPrograms = (programs: Array<any>, sortKey:string) => {
  const now = moment(); // Get the current date and time

  const futurePrograms = [];
  const pastPrograms = [];

  programs.forEach((booking) => {
    const programStartDate = moment(`${booking[sortKey]}`, 'YYYY-MM-DD HH:mm:ss');

    if (programStartDate.isSameOrAfter(now)) {
      futurePrograms.push(booking);
    } else {
      pastPrograms.push(booking);
    }
  });


  futurePrograms.sort((a, b) => {
    const dateA = moment(`${a[sortKey]}`, 'YYYY-MM-DD HH:mm:ss');
    const dateB = moment(`${b[sortKey]}`, 'YYYY-MM-DD HH:mm:ss');
    return dateA.diff(dateB); // Ascending order (nearest date first)
  });

  pastPrograms.sort((a, b) => {
    const dateA = moment(`${a[sortKey]}`, 'YYYY-MM-DD HH:mm:ss');
    const dateB = moment(`${b[sortKey]}`, 'YYYY-MM-DD HH:mm:ss');
    return dateB.diff(dateA); // Descending order (most recent past date first)
  });

  return [...futurePrograms, ...pastPrograms];
}


export const getMissingFieldsMap = (profileCompleteness, customFormUrlPrefix) => {
  let missingFields = [];

  Object.keys(profileCompleteness.forms).forEach((e: string) => {
    let url = MissingFormKeyToUrlMapping[e]
    let fields = profileCompleteness.forms[e].missingFields || []
    fields = fields.map(f => {
      let id, parts;
      let formattedString = f
      const isCustomForm = f.includes('--*--')

      let routeObj = getTitleFromNavLink(url)
      let groupTitle = routeObj?.title || ''


      if (isCustomForm) {
        parts = f.split("--*--");
        id = parts[1].split('(')[0];
        const title = `${parts[1].split('(')[1]}`
        formattedString = `${parts[0]}`;
        url = `/${customFormUrlPrefix}/edit/custom-forms/${id}/${createSlug(title)}`
        groupTitle = title.replace(')', '')
      }

      // if(isCustomForm)  {
      //   groupTitle =
      // }
      return {
        url: url,
        field: formattedString,
        id,
        routeObj,
        groupTitle
      }
    })
    missingFields = [...missingFields, ...fields]
  })

  const groupedData = missingFields.reduce((acc, item) => {
    // Check if the groupTitle already exists in the accumulator
    if (!acc[item.groupTitle]) {
      acc[item.groupTitle] = [];
    }
    // Push the current item into the correct group
    acc[item.groupTitle].push(item);
    return acc;
  }, {});
  return groupedData
  // this.missingFields = groupedData
  // console.log("this.missingFields", this.missingFields)
}


export function orderBy<T>(array: T[], key: keyof T, order: 'asc' | 'desc' = 'asc'): T[] {
  return [...array].sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      const comparison = valueA.localeCompare(valueB);
      return order === 'asc' ? comparison : -comparison;
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      const comparison = valueA - valueB;
      return order === 'asc' ? comparison : -comparison;
    }

    throw new Error(`Unsupported value types for sorting: ${typeof valueA} and ${typeof valueB}`);
  });
}
