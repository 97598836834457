<ng-container *ngIf="tenantError">
  <div class="d-flex justify-content-center align-items-center h-100vh">
    <!-- {{tenantError}} -->

    <div class="card shadow w-75 w-lg-25">
      <div class="card-header d-flex flex-column align-items-center justify-content-center my-4">
        <img width="200px" style="margin-bottom: 10px;" src="assets/images/logos/logo-2.svg">
        <p>SaaS App</p>
      </div>
      <div class="card-body d-flex flex-column justify-content-center ">
        <h3 class=" fs-2 text-center">Direct access is not allowed</h3>
        <p class="card-text text-center">You need to have a partner account with SanchiConnect to get access to the
          panel.</p>
      </div>
    </div>

  </div>

</ng-container>

<ng-container *ngIf="maintainanceMode">
  <div class="d-flex justify-content-center align-items-center h-100vh">

    <div class="card shadow w-75 w-lg-25">
      <div class="card-header d-flex flex-column align-items-center justify-content-center my-4">
        <img width="200px" style="margin-bottom: 10px;" src="assets/images/logos/logo-2.svg">
        <p>SaaS App</p>
      </div>
      <div class="card-body d-flex flex-column justify-content-center ">
        <h3 class=" fs-2 text-center">{{brandDetails.maintenance_mode.maintenance_on_title | titlecase}}</h3>
        <p class="card-text text-center">{{brandDetails.maintenance_mode.maintenance_on_message}}</p>
      </div>
    </div>

  </div>

</ng-container>


<ng-container *ngIf="!subscriptionActive">
  <div class="d-flex justify-content-center align-items-center h-100vh">

    <div class="card shadow w-75 w-lg-25">
      <div class="card-header d-flex flex-column align-items-center justify-content-center my-4">
        <img width="200px" style="margin-bottom: 10px;" src="assets/images/logos/logo-2.svg">
        <p>SaaS App</p>
      </div>
      <div class="card-body d-flex flex-column justify-content-center ">
        <h3 class=" fs-2 text-center">Subscription expired</h3>
        <p class="card-text text-center">{{brandDetails.subscription_active_message}}</p>
      </div>
    </div>

  </div>

</ng-container>

<div *ngIf="brandDetails?.maintenance_mode?.active && maintenanceClosed && !maintainanceMode"
  class="position-absolute top-0 start-0 w-100 maintainance-notice py-5">
  <div></div>
  <div>
    <b>
    {{brandDetails.maintenance_mode.title}}:
    </b>
    {{brandDetails.maintenance_mode.message}}

    <br />
    <span class="svg-icon svg-icon-4 me-1">
      <i class="bi bi-clock" ngbTooltip="Deadline"></i>
    </span>
    {{formatMaintenanceTime(brandDetails.maintenance_mode.startTime)}} - {{formatMaintenanceTime(brandDetails.maintenance_mode.endTime)}}

  </div>
  <div class="me-4  text-white cursor-pointer" (click)="handleCloseNotice()">
    <i class="bi bi-x fs-2hx"></i>
  </div>
</div>

<ng-container *ngIf="!tenantLoading && !tenantError && !maintainanceMode && subscriptionActive">
  <router-outlet></router-outlet>
</ng-container>
