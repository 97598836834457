import { PROFILE_NAV_LINKS } from "./navlinks";

export const MAX_COMPARISION = 4;

export const LOGO_SIZE_LIMIT =  512 * 1024; // 512 kb
export const  meetingTypeLabel = {
  'job-interview': 'Job interview',
  'fundraising': 'Fundraising'
}


export const InterviewAfterStatusLabel  = {
  selected: 'Selected',
  next_round: 'Next round',
  rejected: 'Rejected',
  poor_network: 'Poor network',
  panel_did_not_join: 'Panel did not join',
  candidate_left: 'Candidate left',
  // SELECTED = 'selected',
  // NEXT_ROUND = 'next_round',
  // REJECTED = 'rejected',
  // POOR_NETWORK = 'poor_network',
  // PANEL_DID_NOT_JOIN = 'panel_did_not_join',
  // CANDIDATE_LEFT = 'candidate_left',
}


export const AutoApplyProgramOffSecret = 'aj3839ll'


export const MissingFormKeyToUrlMapping = {
  'startupInformation': '/startups/edit/startup-information',
  'productInformation': '/startups/edit/startup-information',
  'financialInformation': '/startups/edit/financial-details',
  'industryTechnologyBusiness': '/startups/edit/industry-technology',
  'pitch_deck': '/startups/edit/pitch-deck/fundraising-pitch',
  'custom_forms': '/startups/edit/custom-forms/first/first',
  // 'supportingDocument': '/startups/edit/supporting-documents',
  'supportingDocument': '/startups/edit/pitch-deck/fundraising-pitch',

  'investmentInformation': '/investors/edit/investments-details',
  'investorInformation': '/investors/edit/organization-details',
  'representativeInformation': '/investors/edit/representative-details',

  'corporateInformation': '/corporates/edit/corporate-intro',
  'mentorInformation':'/mentors/edit/mentor-information',
  'partnerInformation': '/partners/edit/partner-information',
  'programOfficeMemberInformation': '/program-office-members/edit/program-office-member-information',
  'serviceProviderInformation': '/service-provider/edit/service-provider-information',

  'individualInformation': '/individuals/edit/individual-information'
}

export const getTitleFromNavLink = (url:string) => {
  let menuObj;
  const menu = Object.values(PROFILE_NAV_LINKS).find((link)=> {
    menuObj = link.find(e=>e.route === url)
    return menuObj
  })
  return menuObj
}
